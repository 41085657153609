import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Data Science Degree",
    years: "2020 - 2021",
    content:
      "Degree in data science (Artificial Intelligence, cloud and virtualization).",
    skills: " AI, Python, AWS, Docker, model training ",
    icon: "images/univ.png",
  },
  {
    id: 2,
    title: "Enginner Degree -INSA Toulouse",
    years: "2018 - 2021",
    content:"Toulouse graduate school of engineering, cursus IR (computer science and networks) with specialization Innovative Smart Systems (IoT).",
    skills: " Computer programming, Computer networks, Distributed systems, Security, Human Sciences/Communication/Project management",
    icon: "images/insa.png",
  },
  {
    id: 3,
    title: "CPGE TSI - Jules Ferry Cannes",
    years: "2016 - 2018",
    content:
      "2 years intensive industrial technology science course in preparation for selective exams to engineering schools.",
      skills: " Mathematics/Algebra/DifferentialEquations/statistics, Physics/Mecanics/Thermodynamics/Electromagnetism/Optics, ComputerScience, EnginneringScience/Mechanical/Electrical, English",
      icon: "images/jules.png",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Software Enginner for Collins",
    years: "2022 - Present",
    content:
      "Communication between Aircraft and the Ground. Software Engineering of the Collins AID product",
    skills: " C++, Python, Robot framework, git (Bitbucket), SAFe (Jira, Confluence), Networks, Linux, Google Tests, Redis DB ",
    icon: "images/viveris.png"
  },
  {
    id: 2,
    title: "PLM Consultant for Airbus",
    years: "2021 - 2022",
    content:
      "Testing and automation of performance tests on the 3DEXPERIENCE application. Objective: anticipate performance problems on the 3DEXPERIENCE application. Acquired skills:",
    skills: " Object Oriented Programming, VBscript, Jenkins, virtualization, data visualization, functional skills, SAFe",
    icon: "images/capgemini.png",
  },
  {
    id: 3,
    title: "Internet Of Things Enginner",
    years: "2020 - 2021",
    content:
      "Industrial IoT project in partnership with the company Actia. Localization communication between nodes (smartphones) and gateway (tablet). Implementation of the relative Android application.",
    skills: " Python, Java, communication protocols (BLE, LoRa), multidisciplinary team working ",
    icon: "images/actia.png",
  },
  {
    id: 4,
    title: "Software Enginner",
    years: "2020 - 2021",
    content:
      "Development and implementation of a new add-in (atoll software) to create new radio wave performance maps (quality indicator / KPIs)",
    skills: "C++, C#, Architecture & software design, MFC, COM interfaces, APIs, performance tests ",
    icon: "images/forsk.png",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <h3 align="center"> Education </h3>
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
          <h3 align="center"> Professional Experiences </h3>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;

import React, { useState, useEffect } from "react";
import TrackVisibility from "react-on-screen";
import Markdown from "markdown-to-jsx";
import Header from "../components/layouts/Header";
import Skill from "../components/elements/Skill"
import randomColor from 'randomcolor';
import rehypeRaw from 'rehype-raw'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHtml5, faCss3, faJs, faReact, faJava, faPython, faAndroid, faLinux, faDocker, faNodeJs, faNode} from '@fortawesome/free-brands-svg-icons'
import { faTowerBroadcast, faCircleNodes, faFileLines, faCloud, faPowerOff, faPeopleGroup, faBarsProgress,
        faLightbulb, faDna, faLaptopCode,faTable, faPieChart, faMicrochip, faCode,faNetworkWired,faDiagnoses, faWifi} from '@fortawesome/free-solid-svg-icons'
import ScrollAnimation from "react-animate-on-scroll";
function getRandomColor() {
  const letters = '456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * letters.length);
    color += letters[randomIndex];
  }

  return color;
}

const skillIcons = [
  { name: 'HTML', icon: faHtml5 },
  { name: 'CSS', icon: faCss3 },
  { name: 'JavaScript', icon: faJs },
  { name: 'React', icon: faReact },
  { name: 'Java', icon: faJava },
  { name: 'Python', icon: faPython },
  { name: 'Android', icon: faAndroid },
  { name: 'LoRa', icon: faTowerBroadcast },
  { name: 'BLE', icon: faTowerBroadcast },
  { name: 'RestAPIs', icon: faCircleNodes },
  { name: 'JUnit', icon: faFileLines },
  { name: 'OM2M platform', icon: faCloud },
  { name: 'SpringBoot', icon: faPowerOff },
  { name: 'Linux', icon: faLinux },
  { name: 'Docker', icon: faDocker },
  { name: 'Virtualization', icon: faCloud },
  { name: 'NodeJS', icon: faNodeJs },
  { name: 'NodeRed', icon: faNode },
  { name: 'MQTT', icon: faTowerBroadcast },
  { name: 'Team Work', icon: faPeopleGroup },
  { name: 'Conflict/Risk Management', icon: faBarsProgress },
  { name: 'Creativity', icon: faLightbulb },
  { name: 'R', icon: faLaptopCode },
  { name: 'Data Engineering', icon: faTable },
  { name: 'Data Visualisation', icon: faPieChart },
  { name: 'Sensor Manufactoring', icon: faMicrochip },
  { name: 'Arduino', icon: faMicrochip },
  { name: 'Metrology', icon: faPieChart },
  { name: 'C++', icon: faCode },
  { name: 'Networks', icon: faNetworkWired },
  { name: 'Protocol Analysis', icon: faDiagnoses },
  { name: 'Wireless Communications', icon: faWifi },
  { name: 'Problem Solving', icon: faDna },



  // Add more skills and icons as needed
]


const getIconDelay = () => {
  return `${Math.random() * 10}s`;
};

function displaySkill(skill) {
  const iconObj = skillIcons.find(icon => icon.name === skill);

  if (!iconObj) {
    return null;
  }

  return (
    <div className="icon" style={{animationDelay: getIconDelay() }} key={skill}>
      <FontAwesomeIcon icon={iconObj.icon} size="4x" />
      <div style={{ color:getRandomColor()}}>{skill}</div>
    </div>
  );
}

/* ---- skills to be updated ---- */

const skills = {
  innovativeproject: ['Java','Python','Android','LoRa','BLE'],
  soa: ['Java','RestAPIs','JUnit','OM2M platform','SpringBoot'],
  CloudAndAutonomicComputing:['Docker','Virtualization','Linux','NodeJS'],
  MiddlewareForIoTLab:['MQTT','NodeRed','RestAPIs','OM2M platform'],
  psychology:['Team Work','Conflict/Risk Management'],
  TRIZ:['Creativity','Team Work','Problem Solving'],
  semanticData: ['Java'],
  bigData: ['R', 'Data Engineering', 'Data Visualisation'],
  sensorManufacturing:['Sensor Manufactoring', 'Metrology'],
  mosh:['LoRa','C++', 'Arduino','NodeRed'],
  PFS:['LoRa','Networks','Protocol Analysis','Wireless Communications']
};


const data = {
  CloudAndAutonomicComputing : [
    {
      id: 1,
      title: "Understand the concept of cloud computing",
      percantage: 100,
      progressColor: getRandomColor(),
    },
    {
      id: 2,
      title: "Use a IaaS-type cloud service",
      percantage: 100,
      progressColor: getRandomColor(),
    },
    {
      id: 3,
      title: "Deploy and adapt a cloud-based platform for IoT",
      percantage: 100,
      progressColor: getRandomColor(),
    },

  ],
  MiddlewareForIoTLab: [
   {
     id: 1,
     title: "Situate the main standards for the IoT",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Deploy an IoT standard architecture/sensor network",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Deploy and configure an IoT architecture using OM2M",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 4,
     title: "Interact with the different REST services",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 5,
     title: "Integrate a new technology into the deployed architecture",
     percantage: 100,
     progressColor: getRandomColor(),
   },
 ],
 innovativeproject: [
   {
     id: 1,
     title: "Analyse a real-life problem",
     percantage: 90,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Suggest a technological solution to a problem",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Implement a prototype to solve the problem",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 4,
     title: "Understand and master the optimization of IoT communication protocols at MAC level",
     percantage: 60,
     progressColor: getRandomColor(),
   },
   {
     id: 5,
     title: "Present and debate (in English) the technical choice made",
     percantage: 90,
     progressColor: getRandomColor(),
   },
 ],
 soa: [
   {
     id: 1,
     title: "Know how to define a Service Oriented Architecture",
     percantage: 85,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Deploy an SOA with web services",
     percantage: 95,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Deploy and configure an SOA using SOAP",
     percantage: 70,
     progressColor: getRandomColor(),
   },
   {
     id: 4,
     title: "Deploy and configure an SOA using REST",
     percantage: 90,
     progressColor: randomColor(),
   },
   {
     id: 5,
     title: "Integrate a process manager in an SOA",
     percantage: 55,
     progressColor: getRandomColor(),
   },
 ],
 psychology: [
   {
     id: 1,
     title: "Express and exchange hypotheses",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Suggest a strategy to solve the problem identified",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Multi-disciplinary students work as a team",
     percantage: 100,
     progressColor: getRandomColor(),
   },
 ],
 TRIZ: [
   {
     id: 1,
     title: "Solve a problem in a creative way",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Choose, design and / or justify a protocol or an experimental prototype",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Develop the first stage of innovation",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 4,
     title: "Suggest a model",
     percantage: 100,
     progressColor: getRandomColor(),
   },
 ],
 semanticData: [
   {
     id: 1,
     title: "Design and understand a model for an application",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Know how to infer new knowledge from a knowledge base",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Be able to enrich data with semantic metadata",
     percantage: 100,
     progressColor: getRandomColor(),
   },
 ],
 bigData: [
   {
     id: 1,
     title: "Know how to explore and represent data sets",
     percantage: 80,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Master R",
     percantage: 65,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Manage statistical data complexity and employ techniques for minimizing it.",
     percantage: 50,
     progressColor: getRandomColor(),
   },
 ],
 sensorManufacturing: [
   {
     id: 1,
     title: "Understand sensor basics, data acquisition from a physics, electronics perspective.",
     percantage: 80,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Be able to manufacture a nano-particles sensor using micro-electronics tools",
     percantage: 65,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Be able to design the datasheet of the sensor manufactured",
     percantage: 70,
     progressColor: getRandomColor(),
   },
 ],
 mosh: [
   {
     id: 1,
     title: "Be able to design data acquisition system (sensor, conditioner, microcontroller) with respect to the application",
     percantage: 80,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Be able to design a shield to accommodate the gas sensor",
     percantage: 75,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Be able to design the software to use the gas sensor and its HMI",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 4,
     title: "Understand microcontroller architecture and how to use them",
     percantage: 80,
     progressColor: getRandomColor(),
   },
   {
     id: 5,
     title: "Be able to design the electronic circuit of a sensor’s signal conditioner (design + simulation)",
     percantage: 65,
     progressColor: getRandomColor(),
   },
   {
     id: 6,
     title: "Be able to combine all of the above mentioned components into a smart device",
     percantage: 75,
     progressColor: getRandomColor(),
   },
 ],
 PFS: [
   {
     id: 1,
     title: "Understand and master the new mobile networks technologies",
     percantage: 80,
     progressColor: getRandomColor(),
   },
   {
     id: 2,
     title: "Be able to analyze and evaluate protocols dedicated to Wireless Sensor Networks / IoT",
     percantage: 75,
     progressColor: getRandomColor(),
   },
   {
     id: 3,
     title: "Understand and master the fundamentals of emerging network paradigms applied to IoT",
     percantage: 100,
     progressColor: getRandomColor(),
   },
   {
     id: 4,
     title: "Understand and master the optimization of IoT communication protocols at MAC level",
     percantage: 80,
     progressColor: getRandomColor(),
   },
   {
     id: 5,
     title: "Understand and master the security mechanisms of IoT communication protocols",
     percantage: 65,
     progressColor: getRandomColor(),
   },
   {
     id: 6,
     title: "Mastering the architecture of an energy management system, simple storage, energy recovery",
     percantage: 75,
     progressColor: getRandomColor(),
   },
 ],

};


function BlogDetails(props) {
  const [content, setContent] = useState("");
  //var progressData ;
  const blogFile = props.match.params.title;
  //progressData = eval(blogFile + ';');
  const progressData = data[blogFile]
  const skillsForBlog = skills[blogFile];
  useEffect(() => {
    import(`../blogs/${blogFile}.md`)
      .then((res) => res.default)
      .then((res) => {
        fetch(res)
          .then((result) => result.text())
          .then((result) => setContent(result));
      });
  }, [content, blogFile]);

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  const scrollToBottom = () =>{
    const windowHeight = window.innerHeight;
    const fullHeight = document.body.scrollHeight;
    const scrollTo = fullHeight - windowHeight;
    window.scrollTo({
      top: scrollTo,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };


  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });


  // document.body.classList.add("dark");
  //Uncomment the above line if you use dark version
  const skillElements = skillsForBlog.map(skill => displaySkill(skill));
  return (
    <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
      <div className="load-more text-center mt-4">


        <button
          className="btn btn-default mx-2"
          onClick={scrollToBottom}
        >
            <span>
              <i className="fas fa-spinner"></i> Go to developed skills and knowledge
            </span>

        </button>


      </div>
      <div className="spacer" data-height="60"></div>
        <div className="spacer" data-height="60"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="blog-single shadow-dark p-30">
              <Markdown rehypeplugins={[rehypeRaw]}>{content}</Markdown>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
        {content && (
        <div class="col-md-12 text-center padding-top-10">
          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut">
          <h3> Developped skills and knowledge </h3>
          </ScrollAnimation>
          <div className='container'>
                     {progressData.map((progress) => (
                       <TrackVisibility
                         once
                         key={progress.id}
                         className="progress-wrapper"
                       >
                         <Skill progress={progress} />
                       </TrackVisibility>
                     ))}

          </div>

          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut">
          <h3> Keywords </h3>
          </ScrollAnimation>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex justify-content-center align-items-center h-100">
                <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut">
                  <div className="cloud">
                    {skillElements}
                  </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div> )}

        <div className="spacer" data-height="55"></div>
        <div className="load-more text-center mt-4">


          <button
            className="btn btn-default mx-2"
            onClick={scrollToTop}
          >
              <span>
                <i className="fas fa-spinner"></i> Scroll up
              </span>

          </button>


        </div>

        <div className="spacer" data-height="96"></div>


      </main>
    </>
  );
}

export default BlogDetails;

import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import BlogDetails from "./pages/BlogDetails";
import Works from "./components/sections/Works";
import { Analytics } from '@vercel/analytics/react';
import "./App.scss";

function App() {
  return (

    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Analytics />
          <Homepage />
        </Route>
        <Route path="/work-details/:id/:title" component={BlogDetails} />
        <Route path="/works" component={Works} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

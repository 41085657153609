import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

function Timeline({ education }) {
  const { years, title, content, skills, icon } = education;
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div className="timeline-container">
        <div className="content">
          <span className="time">{years} &nbsp; &nbsp;</span>
          <img src={icon} alt="jules ferry" width="100px"/>
          <h3 className="title">{title}</h3>
          <p>{content}</p>
          <p><b>Acquired skills:</b><i>{skills}</i></p>
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default Timeline;

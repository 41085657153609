import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";

const aboutContent = {
  name: "Abderrazzak MAHRAYE",
  avatarImage: "/images/abdel.JPG",
  content:
    "Hi ! I am Abderrazzak MAHRAYE, IT Engineer from Toulouse, France. I'm graduated with an enginner degree specialized in IoT ( Internet of Things) at INSA Toulouse. I have also a diploma in data science and IA.",
};


const counterData = [
  {
    id: 1,
    title: "Work with heart",
    count: 1,
    icon: "far fa-heart",
  },
  {
    id: 2,
    title: "Cup of coffee",
    count: 500,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "specializations",
    count: 3,
    icon: "fas fa-laptop-code",
  },
  {
    id: 4,
    title: "Diversified trainings",
    count: 23,
    icon: "fa fa-graduation-cap",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div>
                  <p>{aboutContent.content}</p>
                  {/* <div className="mt-3">
                    <a href="images/CV_Abderrazzak_mahraye.pdf" className="btn btn-default">
                      Download CV
                    </a>
                  </div> */}


                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;

import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Blog from "../elements/Blog";



const filters = [
  {
    id: 1,
    text: "Everythings",
  },
  {
    id: 2,
    text: "Innovative Project",
  },
  {
    id: 3,
    text: "Innovation and Humanity",
  },
  {
    id: 4,
    text: "Data Engineering",
  },
  {
    id: 5,
    text: "Smart Devices",
  },
  {
    id: 6,
    text: "Communication",
  },
  {
    id: 7,
    text: "Middleware and Services",
  },
];



const allBlogs = [
  {
    id: 1,
    title: "Localization and Communication",
    image: "images/hoverProject.png",
    filesource: "../../blogs/innovativeproject.md",
    date: "2020",
    author: "A.Mahraye",
    category: "Innovative Project",
  },
  {
    id: 2,
    title: "Service Oriented Architecture: Room management",
    image: "images/hoverSOA.jpg",
    filesource: "../../blogs/soa.md",
    date: "2020",
    author: "mahraye",
    category: "Middleware and Services",
  },
  {
    id: 3,
    title: "Cloud and Autonomic Computing",
    image: "images/hoverCloud.jpg",
    filesource: "../../blogs/CloudAndAutonomicComputing.md",
    date: "2020",
    author: "mahraye",
    category: "Middleware and Services",
  },
  {
    id: 4,
    title: "Middleware for IoT Lab",
    image: "images/hoverMiddle.png",
    filesource: "../../blogs/MiddlewareForIoTLab.md",
    date: "2020",
    author: "mahraye",
    category: "Middleware and Services",
  },
  {
    id: 5,
    title: "Psychology",
    image: "images/hoverPsycho.jpg",
    filesource: "../../blogs/psychology.md",
    date: "2020",
    author: "mahraye",
    category: "Innovation and Humanity",
  },
  {
    id: 6,
    title: "Theory of Inventive Problem Solving",
    image: "images/hoverTRIZ.jpg",
    filesource: "../../blogs/TRIZ.md",
    date: "2020",
    author: "mahraye",
    category: "Innovation and Humanity",
  },
  {
    id: 7,
    title: "Semantic Data",
    image: "images/hoverSem.png",
    filesource: "../../semanticData.md",
    date: "2021",
    author: "mahraye",
    category: "Data Engineering",
  },
  {
    id: 8,
    title: "Data Processing",
    image: "images/hoverBigD.jpg",
    filesource: "../../bigData.md",
    date: "2021",
    author: "mahraye",
    category: "Data Engineering",
  },
  {
    id: 9,
    title: "Sensor Manufactoring",
    image: "images/hoverSensor.png",
    filesource: "../../sensorManufacturing.md",
    date: "2021",
    author: "mahraye",
    category: "Smart Devices",
  },
  {
    id: 10,
    title: "Microcontrollers & Open Source Hardware",
    image: "images/hoverMosh.jpg",
    filesource: "../../mosh.md",
    date: "2021",
    author: "mahraye",
    category: "Smart Devices",
  },
  {
    id: 11,
    title: "Protocols for Wireless Sensor Network",
    image: "images/hoverPFS.png",
    filesource: "../../PFS.md",
    date: "2021",
    author: "mahraye",
    category: "Communication",
  },
];


function Works() {
  const [getAllItems] = useState(allBlogs);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check on component mount

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category.toLowerCase() === e.target.textContent.toLowerCase()
      );
    }
    setVisibleItems(tempData);
  };

  const handleChangeMobile = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.value);
    console.log("text content", e.target.value.toLowerCase())
    let tempData;
    if (e.target.value.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category.toLowerCase() === e.target.value.toLowerCase()
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter.toLowerCase() === filters[0].text.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) =>
              data.category.toLowerCase() === activeFilter.toLowerCase() &&
              data.id <= tempCount
          )
        );
      }
    }
  };

  if (isMobile) {
    return (
      <section id="works">
        <div className="container">
          <Pagetitle title="Works" />

          {/* Start Portfolio Filters (Mobile) */}
          <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut">
            <select
              className="portfolio-filter-mobile"
              value={activeFilter}
              onChange={handleChangeMobile}
            >
              {filters.map((filter) => (
                <option key={filter.id} value={filter.text}>
                  {filter.text}
                </option>
              ))}
            </select>
          </ScrollAnimation>
          {/* End Portfolio Filters (Mobile) */}

          {/* Start Portfolio Items */}
          <div className="row portfolio-wrapper">
            {visibleItems.map((item) => (
              <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
                <Blog blogData={item} />
              </div>
            ))}
          </div>
          {/* End Portfolio Items */}

          {activeFilter.toLowerCase() === filters[0].text.toLowerCase() && (
            <div className="load-more text-center mt-4">
              <button
                className="btn btn-default"
                onClick={handleLoadmore}
                disabled={noMorePost ? "disabled" : null}
              >
                {noMorePost ? (
                  "No more items"
                ) : (
                  <span>
                    <i className="fas fa-spinner"></i> Load more
                  </span>
                )}
              </button>
            </div>
          )}
        </div>
      </section>
    );
  }

  // Render the usual code for non-mobile devices
  return (
    <section id="works">
      <div className="container">
        <Pagetitle title="Works" />

        {/* Start Portfolio Filters (Desktop) */}
        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut">
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters (Desktop) */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Blog blogData={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        {activeFilter.toLowerCase() === filters[0].text.toLowerCase() && (
          <div className="load-more text-center mt-4">
            <button
              className="btn btn-default"
              onClick={handleLoadmore}
              disabled={noMorePost ? "disabled" : null}
            >
              {noMorePost ? (
                "No more items"
              ) : (
                <span>
                  <i className="fas fa-spinner"></i> Load more
                </span>
              )}
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

export default Works;

import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
function Skill({ progress, isVisible }) {
  const { title, percantage, progressColor } = progress;

  const progressBarStyle = isVisible ? {
                  width: `${percantage}%`,
                  background: progressColor,
                }
              : { width: 0, background: progressColor };


  return (
    <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut">

    <div className="skill-item">
      <div className="skill-info clearfix">
        <span className="float-right">{percantage}%</span>
      </div>
      <div className="progress" style={{ height: 20 }}>
        <div className="progress-bar" style={progressBarStyle}>
          <span className="progress-text">{title}</span>
        </div>
      </div>
    </div>
    </ScrollAnimation>
  );
}

export default Skill;
